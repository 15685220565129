import * as React from "react"
import { graphql } from "gatsby"
import { StoreContext } from "../context/store-context"
import ProductListing from "../components/product-listing"
import { Layout } from "../components/layout/layout"

export default function FavoritePage({
    data: { allProducts }
}) {
    const [products, setProducts] = React.useState([])
    const { favorites } = React.useContext(StoreContext)

    React.useEffect(() => {
        const favoritedProducts = []
        allProducts?.nodes?.forEach((product) => {
            favorites.forEach((favorite) => {
                if (product.handle === favorite.handle) {
                    favoritedProducts.push(product)
                }
            })
        })
        setProducts(favoritedProducts)
    }, [allProducts, favorites, setProducts])

    return (
        <Layout>
            <div>
                <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                    <div className="text-4xl font-bold w-full text-center">
                        <h1 className="leading-tight font-normal text-45xl">My Favorites</h1>
                    </div>
                </div>
            </div>
            <div className="sm:px-4 md:px-8 mx-auto max-w-screen-xl">
                <ProductListing products={products} template="favourites" viewMode="list" />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    allProducts: allShopifyProduct {
        nodes {
            ...ProductCard
        }
    }
  }
`